/* You can add global styles to this file, and also import other style files */
*{
  font-weight: bold !important;
}
.ngx-datatable .datatable-body datatable-body-row:hover {
    background-color: #007bff2e !important;
  }
/* .ngx-datatable .datatable-body .datatable-body-row:hover{
    background: red;
} */

